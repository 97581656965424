function resetState(store) {
  store.commit('ao_vivo/resetState')
  store.commit('ao_vivo/chat/resetState')
  store.commit('ao_vivo/events/resetState')
  store.commit('ao_vivo/stream/resetState')
  store.commit('forms/resetState')
  store.commit('feed/resetState')
  store.commit('resetState')
  store.commit('moderation/resetState')
  store.commit('notifications/resetState')
  store.commit('portfolios/resetState')
  store.commit('recommendedPortfolios/resetState')
  store.commit('simulator/resetState')
  store.commit('trades/resetState')
  store.commit('upload/resetState')
}

export default resetState
