<script>
import { mapFields } from 'vuex-map-fields'
import resetState from '@/assets/js/resetState'

export default {
  data() {
    return {
      guestSignupBenefits: [
        'Carteiras Recomendadas de <strong>Ações</strong>, <strong>Trading</strong>, <strong>Dividendos</strong>, <strong>Small Caps</strong>, <strong>FIIs</strong>, <strong>BDRs</strong> e <strong>Cripto</strong> em <strong>tempo real</strong>.',
        'Track record <strong>100% transparente</strong> para você encontrar as <strong>melhores oportunidades</strong>.',
        'Recomendações e relatórios <strong>direto no seu WhatsApp</strong>.',
        '<strong>Feed</strong> com análises, insights e atualizações.',
        '<strong>Sala exclusiva</strong> com Analistas Profissionais.',
        'Comunicação e conteúdo <strong>livre de marketing apelativo.</strong>',
      ],
      contexts: {
        portfolio: {
          title: 'Cadastre-se para visualizar todos os detalhes da Carteira $$$',
          toolTipText: null,
          imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_450/cdicombr/cdi/assets/ctas/recommendation_cta',
        },
        recommendation: {
          title: 'Veja os <span class="highlight">dados de performance</span> e a análise completa dessa recomendação $$$',
          toolTipText: 'Dados de performance ajudam você a tomar melhores decisões de investimento.',
          imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_450/cdicombr/cdi/assets/ctas/recommendation_cta',
        },
        pdf: {
          title: '<span class="highlight">Receba em PDF</span> recomendações e atualizações das carteiras que estiver seguindo.',
          toolTipText: 'Todo especialistas que recomenda investimentos precisar ter uma certificação CNPI.',
          imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_450/cdicombr/cdi/assets/ctas/recommendation_cta',
        },
        feed: {
          title: 'Acesse agora o melhor conteúdo para traders e investidores da bolsa de valores',
          toolTipText: null,
          imgSrc: null,
        },
        whatsapp: {
          title: 'Receba no <span class="highlight">WhatsApp</span>, email e em tempo real na plataforma todas as ações que você deve investir.',
          toolTipText: 'Você só é notificado quando algo importante acontece nas carteiras que você está seguindo.',
          imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_450/cdicombr/cdi/assets/ctas/whatsapp_cta',
        },
        follow: {
          title: 'Siga mais de uma carteira para <span class="highlight">diversificar</span> sua estratégia de investimento.',
          toolTipText: 'Diversificar no longo prazo é importante porque ajuda a reduzir o risco.',
          imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_450/cdicombr/cdi/assets/ctas/follow_cta',
        },
        live: {
          title:
            this.$activeRealm === 'pdt'
              ? 'Junte-se a 600 mil traders, aprenda com cursos gratuitos e <span class="highlight">siga trades em tempo real</span>.'
              : 'Tire dúvidas sobre as carteiras e recomendações direto com <span class="highlight">analistas certificados</span>.',
          toolTipText: 'Todo especialistas que recomenda investimentos precisar ter uma certificação CNPI',
          imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_450/cdicombr/cdi/assets/ctas/live_stream_cta',
        },
        inter: {
          title: 'Baixe o Super App </br> Inter e simplifique o </br> seu dia a dia',
          subtitle: 'Compre on-line, faça transferências via Pix e ganhe cashback. Tudo isso e muito mais, em um só lugar.',
          secondaryCta: 'Conheça as vantagens do nosso Super App',
          appStore: 'https://apps.apple.com/br/app/banco-inter/id839711154',
          gPlay: 'https://play.google.com/store/apps/details?id=br.com.intermedium',
          imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/q_auto:good,dpr_auto/inter/assets/inter_investimentos_JornadaTrader',
        },
        generic: {
          title: 'Você ainda não se cadastrou na Carteira do Investidor?',
          toolTipText: null,
          imgSrc: null,
          items: [
            {
              icon: ['fas', 'plus'],
              text: 'Aumente suas chances de sucesso na Bolsa de Valores com nossa metodologia proprietária.',
            },
            {
              icon: ['fas', 'bolt'],
              text: 'Fuja das armadilhas e não perca dinheiro com erros básicos no mercado. Nossa proposta é séria, sem promessas milagrosas.',
            },
            {
              icon: ['fas', 'chart-line'],
              text: 'Aprenda as melhores estratégias utilizadas por investidores e traders profissionais através de um sistema educacional completo.',
            },
            {
              // icon: ['fal', 'bell-on'],
              icon: ['fas', 'users'],
              text: 'Converse com outros traders e investidores em todos os nossos canais',
            },
            {
              icon: ['fas', 'calendar-alt'],
              text: 'Aulas, Transmissões, Notícias e Calendário Econônico Ao Vivo todos os dias.',
            },
            {
              icon: ['fas', 'user-chart'],
              text: 'Plataforma de e-learning EAD completa com centenas de vídeos gratuitos, artigos, perguntas de fixação e discussão.',
            },
          ],
        },
      },
    }
  },
  computed: {
    ...mapFields('auth', ['user.hats.guest']),
    ...mapFields('realms', ['realms']),

    isGuestUser() {
      const realmsThatAllowGuestUsers = ['pdt', 'inter']
      return this.guest && realmsThatAllowGuestUsers.includes(this.$activeRealm)
    },
  },
  methods: {
    async goToCheckout() {
      if (this.$activeRealm === 'inter') {
        this.$nuxt.$emit('request-guest-user-signup-modal')
        return
      }
      const currentUrl = this.$route.fullPath
      resetState(this.$store)
      await this.$auth.logout()
      if (this.$activeRealm === 'pdt') window.location.replace(`https://portaldotrader.com.br/entrar?backUrl=${encodeURIComponent(window.location.href)}`)
      else this.$router.push({ path: `/checkout`, query: { backUrl: currentUrl } })
    },
    shouldBlurResults(index, openTradesArr) {
      if (!this.isGuestUser) return false
      const openTradesLength = openTradesArr?.length
      if (openTradesLength <= 1) return true
      const halfTheAmount = Math.ceil(openTradesLength / 2)
      return index >= halfTheAmount
    },
  },
}
</script>
