<script>
import moment from 'moment'
import 'moment/locale/pt-br'
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('auth', ['hat']),
    ...mapFields('subscription', [
      'yearlyPlanOriginalPrice',
      'monthlyPlanOriginalPrice',
      'initialDiscount',
      'trialDiscount',
      'cookieVoucherCode',
      'cookie499Expiry',
    ]),
    ...mapFields('voucherData', {
      voucherData: 'voucherData',
    }),
    ...mapFields('auth', {
      loggedIn: 'loggedIn',
      activeSubscription: 'user.subscription.active',
      expiry: 'user.subscription.expiry',
      subscriptionId: 'user.subscription.subscriptionId',
      received30DaysExtension: 'user.subscription.received30DaysExtension',
      invitees: 'user.invitees',
      invoiceUrl: 'user.subscription.pendingInvoiceUrl',
      pendingFirstPix: 'user.subscription.pendingFirstPix',
      trialStart: 'user.subscription.trialStart',
      qrCode: 'user.subscription.pendingInvoiceQrCode',
      pix: 'user.subscription.pix',
      subscriptionPlan: 'user.subscription.plan',
      secondPixExpiry: 'user.subscription.secondPixExpiry',
      invoiceExpiry: 'user.subscription.invoiceExpiry',
      pendingInvoicePixText: 'user.subscription.pendingInvoicePixText',
      agreedAmountInCents: 'user.subscription.agreedAmountInCents',
      fullAmountInCents: 'user.subscription.fullAmountInCents',
      receivedExtension: 'user.subscription.receivedExtension ',
    }),

    currentTime() {
      return moment()
    },

    // Trial
    localizedExpiry() {
      return new Intl.DateTimeFormat('pt-BR', { day: 'numeric', month: 'long', year: 'numeric' }).format(
        moment(this.pix ? this.secondPixExpiry : this.expiry, 'YYYYMMDD').toDate()
      )
    },
    hasTrial() {
      if (!this.loggedIn) return false
      return this.currentTime.isSameOrBefore(moment(this.expiry), 'minutes') && (!this.subscribedAtLeastOnce || this.pendingFirstPix)
    },
    hoursLeft() {
      return moment(this.expiry).diff(this.currentTime, 'hours', true)
    },
    toEndTrial() {
      if (this.hoursLeft < 24) return Math.round(this.hoursLeft)
      return moment(this.expiry).diff(this.currentTime, 'days')
    },
    trialPeriod() {
      return !this.pix && this.received30DaysExtension ? 30 : 7
    },
    trialSpent() {
      return this.trialPeriod - (this.hoursLeft < 24 ? 0 : this.toEndTrial)
    },
    expired() {
      return !this.hasTrial && !this.activeSubscription && !this.inUnsubscriptionProcess
    },
    timeDisplayMode() {
      return this.hoursLeft < 24 ? 'hours' : 'days'
    },
    leftPeriodLabel() {
      return this.timeDisplayMode === 'hours' ? (this.toEndTrial <= 1 ? 'hora' : 'horas') : this.toEndTrial <= 1 ? 'dia' : 'dias'
    },

    // Subscription
    isSubscriber() {
      if (this.hat('su')) return true
      // if (!this.loggedIn) return false
      return this.activeSubscription
    },
    subscribedAtLeastOnce() {
      return !!this.subscriptionId
    },
    activatedInvites() {
      return this.invitees.length
    },
    comboSubscription() {
      return this.subscriptionPlan === 'plano-tnt'
    },
    subscriberWithTrial() {
      return moment(this.trialStart).add(8, 'days').isSameOrAfter(this.currentTime) && this.subscribedAtLeastOnce
    },
    subscriptionHas499Discount() {
      return this.agreedAmountInCents === 499
    },

    // Unsubscription
    inUnsubscriptionProcess() {
      return this.subscribedAtLeastOnce && !this.activeSubscription && this.hoursLeft > 0 && !this.pendingFirstPix
    },

    // Voucher
    voucherCode() {
      if (this.has499Voucher) return '499'
      return (!this.expiredVoucher && this.voucherData?.voucherCode) || null
    },
    voucherExpiry() {
      if (this.has499Voucher) return this.voucher499Expiry
      if (this.voucherData?.valid && !this.voucherData?.expiry) return moment().add(this.trialPeriod, 'days')
      return (this.voucherData?.valid && this.voucherData?.expiry) || null
    },
    localizedVoucherExpiry() {
      if (!this.voucherExpiry) return ''
      return new Intl.DateTimeFormat('pt-BR', { day: 'numeric', month: 'long', year: 'numeric' }).format(moment(this.voucherExpiry).subtract(1, 'days'))
    },
    toExpireVoucher() {
      if (this.voucherLeftHours < 24) return Math.round(this.voucherLeftHours)
      return moment(this.voucherExpiry).diff(this.currentTime, 'days')
    },
    voucherLeftHours() {
      return moment(this.voucherExpiry).diff(this.currentTime, 'hours', true)
    },
    voucherTimeDisplayMode() {
      return this.voucherLeftHours < 24 ? 'hours' : 'days'
    },
    voucherLeftPeriodLabel() {
      return this.voucherTimeDisplayMode === 'hours' ? (this.toExpireVoucher <= 1 ? 'hora' : 'horas') : this.toExpireVoucher <= 1 ? 'dia' : 'dias'
    },
    expiredVoucher() {
      if (this.has499Voucher) return !this.hasValid499Voucher
      if (!this.voucherExpiry || this.subscribedAtLeastOnce) return true
      return this.currentTime.isSameOrAfter(moment(this.voucherExpiry), 'hours')
    },
    // Voucher 499
    has499Voucher() {
      return this.cookieVoucherCode?.toString() === '499' || this.$cookies.get('voucher')?.toString() === '499'
    },
    has499Expiry() {
      return !!this.cookie499Expiry || !!this.$cookies.get('499Expiry')
    },
    voucher499Expiry() {
      return moment(this.$cookies.get('499Expiry')).toDate()
    },
    hasValid499Voucher() {
      if (!this.has499Expiry || !this.has499Voucher) return false
      const today = moment(this.currentTime).set({ hour: 0, minute: 0, second: 0 })
      const expiry = moment(this.expiry).set({ hour: 23, minute: 59, second: 59 })
      // const d1 = moment(this.currentTime).add(1, 'days').set({ hour: 23, minute: 59, second: 59 })
      return moment(this.voucher499Expiry).isBetween(today, expiry, 'days', '[]')
    },

    // Trial Modifiers
    voucherTrialBonus() {
      return this.voucherData?.voucherTrialBonusInDays || null
    },

    // Price Modifiers
    voucherMonthlyDiscount() {
      return (!this.expiredVoucher && this.voucherData?.valid && this.voucherData?.monthlyDiscountInCents) || 0
    },
    voucherYearlyDiscount() {
      return (!this.expiredVoucher && this.voucherData?.valid && this.voucherData?.yearlyDiscountInCents) || 0
    },
    discountedMonthlyPlan() {
      if (this.has499Voucher && this.hasValid499Voucher) return 4.99
      const trialDiscount = this.hasTrial && !this.voucherMonthlyDiscount ? this.trialDiscount : 0
      return Math.round(this.monthlyPlanOriginalPrice * (1 - this.initialDiscount) * (1 - trialDiscount) - this.voucherMonthlyDiscount / 100)
    },
    discountedYearlyPlan() {
      if (this.has499Voucher && this.hasValid499Voucher) return 49.0
      const trialDiscount = this.hasTrial && !this.voucherYearlyDiscount ? this.trialDiscount : 0
      return Math.round(this.yearlyPlanOriginalPrice * (1 - this.initialDiscount) * (1 - trialDiscount) - this.voucherYearlyDiscount / 100)
    },
    adjustedMonthlyDiscount() {
      return 1 - this.discountedMonthlyPlan / this.monthlyPlanOriginalPrice
    },
    adjustedYearlyDiscount() {
      return 1 - this.discountedYearlyPlan / this.yearlyPlanOriginalPrice
    },
  },
  methods: {
    set499Voucher() {
      if (this.$cookies.get('voucher') === '499') return
      this.$cookies.set('voucher', '499', { path: '/', expires: this.set499D1Expiry() })
    },
    set499Expiry() {
      if (this.$cookies.get('499Expiry')) return
      const expiry = this.set499D1Expiry()
      this.$cookies.set('499Expiry', expiry, { path: '/', expires: this.set499D1Expiry() })
      this.cookie499Expiry = expiry
    },
    set499D1Expiry() {
      return moment(this.expiry).set({ hour: 23, minute: 59, second: 59 }).toDate()
      // return moment().add(1, 'days').set({ hour: 23, minute: 59, second: 59 }).toDate()
    },
  },
}
</script>
