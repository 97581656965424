import _ from 'lodash'

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

function objectId(date) {
  if (!date) date = new Date()
  return Math.floor(date.getTime() / 1000).toString(16) + '0000000000000000'
}

function dateFromObjectId(objectId) {
  objectId = objectId.toString()
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000)
}

function formatDate(date, time = true) {
  if (!date) return
  const timeStyleParam = time ? { dateStyle: 'short', timeStyle: 'short' } : { dateStyle: 'short' }
  return new Date(date).toLocaleString('pt-BR', Object.assign(timeStyleParam))
}

function formatPrice(num, decimals = 2) {
  if (num !== 0 && !num) return num
  // if (num >= 10000) decimals = 0
  return num.toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
}

function decimalPlaces(symbol) {
  if (/^(IND|WIN)/.test(symbol)) return 0
  return 2
}

function formatFutureResult(entry, quote, side, symbol, decimalPlaces) {
  if (symbol.substr(0, 3) === 'WDO' || symbol.substr(0, 3) === 'DOL') {
    if (side === 'COMPRA') {
      const result = quote - entry
      // Use decimalPlaces para decimais originais em minimumFractionDigits e maximumFractionDigits
      return result.toLocaleString('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    } else {
      const result = entry - quote
      return result.toLocaleString('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    }
  } else if (symbol.substr(0, 3) === 'WIN' || symbol.substr(0, 3) === 'IND') {
    if (side === 'COMPRA') {
      const result = quote - entry
      return result.toLocaleString('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    } else {
      const result = entry - quote
      return result.toLocaleString('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    }
  }
}

function formatFuturePrice(entry, exit, side, symbol, decimalPlaces) {
  if (symbol.substr(0, 3) === 'WIN' || symbol.substr(0, 3) === 'IND') {
    if (side === 'COMPRA') {
      const result = exit - entry
      return result.toLocaleString('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    } else {
      const result = entry - exit
      return result.toLocaleString('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    }
  } else if (symbol.substr(0, 3) === 'WDO' || symbol.substr(0, 3) === 'DOL') {
    if (side === 'COMPRA') {
      const result = exit - entry
      return result.toLocaleString('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    } else {
      const result = entry - exit
      return result.toLocaleString('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    }
  }
}

function formatBigPrice(num) {
  if (num !== 0 && !num) return num
  return num.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

function formatTrade(trade) {
  const symbol = trade.symbol[0]
  const quoteAsset = trade.cashflow[0].quoteAsset
  const isOpen = trade.status === 'open'
  const side = trade.side[symbol]
  const entryPrice = ['open', 'closed'].includes(trade.status) ? trade.entryPrice[symbol] : trade.cashflow[0].price
  const exitPrice = trade.exitPrice[symbol]
  const entryCurrencyFactor = trade.entryCurrencyFactor[symbol]
  const exitCurrencyFactor = trade.exitCurrencyFactor[symbol]
  const entryAmount = trade.cashflow[0].adjustedAmount
  const exitAmount = trade.exitAmount[symbol]
  const decimalPlaces = trade.decimalPlaces[symbol]
  const exits = trade.cashflow.slice(1).map((cf) => ({
    adjustedPrice: cf.adjustedPrice,
    amount: Math.abs(cf.amount),
    executed: cf.executed,
    executionType: cf.executionType,
    auctionType: cf.auctionType || null,
    auctionDate: cf.auctionDate || null,
    parametricOrder: cf.parametricOrder,
    parametricContext: cf.parametricContext || null,
    percentage: Math.abs(cf.tradePercentage),
    effectiveTradePercentage: Math.abs(cf.effectiveTradePercentage) || null,
    price: cf.price,
    triggerPrice: cf.triggerPrice,
    timestamp: cf.timestamp,
    orderId: cf.orderId,
  }))
  const executedExits = _.orderBy(
    exits.filter((exit) => exit.executed),
    'timestamp',
    'asc'
  )
  const pendingExits = _.orderBy(
    exits.filter((exit) => !exit.executed),
    'price',
    side > 0 ? 'asc' : 'desc'
  )

  trade.cashflow.forEach((cf) => {
    if (!cf.tradePercentage) cf.tradePercentage = cf.effectiveTradePercentage
    if (!cf.equityPercentage) cf.equityPercentage = cf.effectiveEquityPercentage
  })

  const formattedTrade = {
    ...trade,
    symbol,
    quoteAsset,
    entryCurrencyFactor,
    exitCurrencyFactor,
    isOpen,
    numeric: {
      side,
      entryPrice,
      exitPrice,
      exitAmount,
      openAmount: entryAmount + exitAmount,
    },
    side: side > 0 ? 'COMPRA' : 'VENDA',
    entryAmount,
    entryPrice: formatPrice(entryPrice, decimalPlaces),
    limitPrice: formatPrice(trade.cashflow[0].limitPrice, decimalPlaces),
    exitPrice: formatPrice(exitPrice, decimalPlaces),
    entryTimestamp: isOpen ? trade.entryTimestamp : trade.cashflow[0].timestamp,
    exitTimestamp: trade.exitTimestamp,
    targetPrice: formatPrice(trade.targetPrice, decimalPlaces),
    partialTargetPrice: formatPrice(trade.partialTargetPrice, decimalPlaces),
    stopPrice: formatPrice(trade.stopPrice, decimalPlaces),
    exits: [...pendingExits, ...executedExits],
    decimalPlaces,
    lastCashflow: trade.cashflow[trade.cashflow.length - 1].timestamp,
    result: exitPrice && entryPrice ? parseFloat(((side * (exitPrice - entryPrice)) / entryPrice) * 100) : 0,
  }
  if (trade.entryType === 'start') formattedTrade.triggerPrice = formatPrice(trade.cashflow[0].triggerPrice, decimalPlaces)
  return formattedTrade
}

function getYoutubeThumbnail(youtubeId, small = true) {
  if (youtubeId === null) return ''
  const resolution = small ? 'mqdefault' : 'maxresdefault'
  return 'https://img.youtube.com/vi/' + youtubeId + '/' + resolution + '.jpg'
}

function getVideoId(url) {
  if (!url) return null
  const rg = /(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
  const matchVideoId = url.match(rg)
  return matchVideoId?.[1] || null
}

function floatingResult(trade, quote, currencyFactor) {
  const realizedEntryPrice = trade.numeric.entryPrice * trade.entryCurrencyFactor
  const realizedExitPrice = trade.numeric.exitPrice * trade.exitCurrencyFactor
  const realizedQuote = quote * currencyFactor
  return (
    ((trade.numeric.openAmount * (realizedQuote - realizedEntryPrice)) / realizedEntryPrice +
      (trade.numeric.exitAmount * (realizedExitPrice - realizedEntryPrice)) / realizedEntryPrice) /
    trade.entryAmount
  )
}
async function requestProfile(symbol) {
  const profileData = await this.$axios.get(`/api/instruments/profile?symbol=${symbol}`)
  this.assetProfile = profileData.data || null
}

function genUniqueToken() {
  return Math.random().toString(36).substring(2)
}

export {
  sleep,
  objectId,
  formatPrice,
  formatFuturePrice,
  formatFutureResult,
  formatBigPrice,
  formatDate,
  formatTrade,
  getYoutubeThumbnail,
  getVideoId,
  dateFromObjectId,
  floatingResult,
  requestProfile,
  genUniqueToken,
  decimalPlaces,
}
