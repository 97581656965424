<template>
  <div class="navbar-container">
    <DesktopStyle />
    <MobileStyle />
  </div>
</template>

<script>
export default {
  components: {
    DesktopStyle: () => import('@/components/navbar/DesktopStyle'),
    MobileStyle: () => import('@/components/navbar/MobileBottomStyle'),
  },
}
</script>

<style lang="scss" scoped>
.navbar-container {
  @include media-breakpoint-up(xl) {
    height: 55px;
  }
  @media (max-height: 450px) {
    display: none !important;
  }
  ::v-deep {
    .navbar {
      background: $cdi-primary-blue;
      color: white;
      min-height: 50px;
    }
    .active {
      @include media-breakpoint-up(xl) {
        position: relative;
        background: linear-gradient(to top, rgba(white, 0.1), rgba(white, 0));
        transition: all ease-in-out 100ms;

        &::after {
          content: '';
          position: absolute !important;
          bottom: 0px !important;
          height: 4px !important;
        }
      }
    }
  }
}
</style>
