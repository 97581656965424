<template>
  <div>
    <template v-if="onPortfolio">
      <NewVideoModal v-if="hat('cnpi', ['*'])" :mode="newVideoMode" :video="video" :portfolio-id="portfolioId" />
      <DeprecatedPortfolioModal :portfolio-id="portfolioId" />
    </template>

    <!-- <template v-if="onFeed || onPortfolio || onSeguindo">
    </template> -->

    <template v-if="onFeed || onPortfolio || onLive">
      <UpdateFeedModal />
      <!-- <NewFeedModal v-if="!event" :portfolio-id="portfolioId" /> -->
      <!-- <UpdateFeedModal v-else :mode="feedMode" :event="event" /> -->
      <!-- <VideoFeedModal ref="video_modal" :portfolio-id="portfolioId" :video="video" /> -->
      <TextUpdateModal ref="text_update_modal" :text-update="textUpdate" />
      <EventToPDFModal :event-data="eventData" />
      <NewTradeIdea :portfolio-slug="portfolioSlug" />
    </template>

    <template v-if="onLive">
      <ImageModal />
      <UploadModal v-if="hat('uploader', ['*'])" />
      <UserModerationModal v-if="hat('moderator', ['*'])" />
      <!-- <InterEmailModal /> -->
    </template>

    <template v-if="onFeed">
      <VoucherModal ref="voucher_modal" />
    </template>

    <template v-if="onSubscription">
      <UpdateCCModal />
      <SuspendSubscriptionModal />
      <!-- <SubscriptionWarning /> -->
    </template>

    <template v-if="isGuestUser">
      <SignUpModal :context="signupCtaContext" :context-specific="signupCtaContextSpecific" />
    </template>

    <template v-if="loggedIn">
      <OnboardingModal ref="onboarding_modal" />
      <VideoFeedModal ref="video_modal" :portfolio-id="portfolioId" :video="video" />
      <LoginModal />
      <TradeDetail v-if="!!tradeInfo" ref="trade_detail_modal" :event-source="eventSource" :trade-info="tradeInfo" />
      <HistoryModal :portfolio-id="portfolioId" :portfolio-name="portfolioName" />
      <AllocationDetail
        :trade="trade"
        :portfolio="portfolio"
        :event-source="eventSource"
        :open-trades="allocationOpenTrades"
        :waiting-trades="allocationWaitingTrades"
      />
      <NewPortfolio v-if="hat('cnpi', ['*']) || instanceAllowsPrivatePortfolio()" :mode="newPortfolioMode" :portfolio-id="newPortfolioId" />
      <StreamAdminModal v-if="hat('liveAdmin', ['*'])" />
      <!-- <FirstFollowModal ref="first_follow_modal" :portfolio="portfolio" /> -->
      <SubscriptionModal v-if="expired" />
      <CompleteProfileModal v-if="!email || pdfOnly" ref="complete_profile_modal" />
      <PortfoliosModal />
      <MirroringModal v-if="hat('amateurAnalyst', ['*'])" />
      <PortfolioModal v-if="portfolioModalSlug" :portfolio-slug="portfolioModalSlug" />
      <AvatarModal />
    </template>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapMutations, mapGetters } from 'vuex'
import _ from 'lodash'
import handleSubscription from '@/components/mixins/handleSubscription'
import handleGuestUser from '@/components/mixins/handleGuestUser'

export default {
  name: 'ModalsController',
  components: {
    // navbar modals
    LoginModal: () => import('@/components/modals/navbar/LoginModal'),
    CompleteProfileModal: () => import('@/components/modals/CompleteProfileModal'),
    // portfolios and trades modals
    NewPortfolio: () => import('@/components/modals/portfolio/NewPortfolio'),
    TradeDetail: () => import('@/components/modals/portfolio/TradeDetail'),
    AllocationDetail: () => import('@/components/modals/portfolio/AllocationDetail'),
    HistoryModal: () => import('@/components/modals/portfolio/HistoryModal'),
    VideoFeedModal: () => import('@/components/modals/portfolio/VideoFeedModal'),
    NewVideoModal: () => import('@/components/modals/portfolio/NewVideoModal'),
    TextUpdateModal: () => import('@/components/modals/portfolio/TextUpdadeModal'),
    MirroringModal: () => import('@/components/modals/portfolio/MirroringModal'),
    DeprecatedPortfolioModal: () => import('@/components/modals/portfolio/DeprecatedPortfolioModal'),
    PortfolioModal: () => import('@/components/modals/portfolio/PortfolioModal'),
    NewTradeIdea: () => import('@/components/modals/AmateurAnalystTradeIdeaModal'),
    // aovivo modals
    ImageModal: () => import('@/components/modals/aovivo/ImageModal'),
    StreamAdminModal: () => import('@/components/modals/aovivo/StreamAdminModal'),
    UploadModal: () => import('@/components/modals/aovivo/UploadModal'),
    AvatarModal: () => import('@/components/modals/aovivo/AvatarModal'),
    UserModerationModal: () => import('@/components/modals/aovivo/UserModerationModal'),
    // InterEmailModal: () => import('@/components/inter/modals/InterEmailModal'),
    // feed modalsController
    UpdateFeedModal: () => import('@/components/modals/feed/UpdateFeedModal'),
    EventToPDFModal: () => import('@/components/modals/feed/EventToPDFModal'),
    // FirstFollowModal: () => import('@/components/modals/FirstFollowModal'),
    // Subscription Modal
    SubscriptionModal: () => import('@/components/subscription-components/expired/ExpiredModal'),
    VoucherModal: () => import('@/components/modals/VoucherModal'),
    OnboardingModal: () => import('@/components/modals/OnboardingModal'),
    UpdateCCModal: () => import('@/components/modals/UpdateCCModal'),
    SuspendSubscriptionModal: () => import('@/components/modals/SuspendSubscriptionModal'),
    PortfoliosModal: () => import('@/components/modals/PortfoliosModal'),
    SignUpModal: () => import('@/components/modals/SignUpModal'),
    // SubscriptionWarning: () => import('@/components/modals/SubscriptionWarning'),
  },
  mixins: [handleSubscription, handleGuestUser],
  data() {
    return {
      trade: {},
      tradeInfo: null,
      newPortfolioMode: 'newPortfolio',
      portfolio: null,
      portfolioId: null,
      allocationOpenTrades: null,
      allocationWaitingTrades: null,
      portfolioName: null,
      portfolioSlug: null,
      newPortfolioId: null,
      newVideoMode: 'newVideo',
      video: {},
      eventSource: null,
      // event: null,
      firstFollow: false,
      onboarding: null,
      updateCC: null,
      textUpdate: null,
      report: null,
      tradeDetail: null,
      videoModal: null,
      // feedMode: null,
      eventData: null,
      signupCtaContext: null,
      signupCtaContextSpecific: null,
      portfolioModalSlug: '',
    }
  },
  computed: {
    ...mapGetters('auth', ['hat']),
    ...mapGetters('realms', ['instanceAllowsPrivatePortfolio']),
    ...mapFields('updateFormData', { feedPortfolioId: 'portfolioId', feedMode: 'mode', feedEvent: 'event' }),
    ...mapFields(['loginModalMode']),
    ...mapFields('auth', ['loggedIn', 'user.auth.email', 'user.flags.pdfOnly']),
    ...mapFields('portfolios', ['portfolioList']),

    onCarteiras() {
      return this.$route.name === 'carteiras'
    },
    onFeed() {
      return this.$route.name === 'feed'
    },
    onPortfolio() {
      return this.$route.name === 'carteira-portfolioSlug'
    },
    onSeguindo() {
      return this.$route.name === 'seguindo'
    },
    onLive() {
      // return this.$route.name === 'aovivo'
      return ['aovivo', 'canal-roomSlug'].includes(this.$route.name)
    },
    onSubscription() {
      return this.$route.name === 'minha-assinatura'
    },
  },

  created() {
    this.$nextTick(() => {
      // Navbar
      this.$nuxt.$on('modals-login-modal', (mode) => {
        if (mode) this.loginModalMode = mode
        this.openModal('login_modal')
      })

      // Portfolios and Trades
      this.$nuxt.$on('request-subscription-modal', () => {
        this.openModal('subscription_modal')
      })
      this.$nuxt.$on('modals-new-portfolio', (mode, portfolioId) => {
        if (mode) this.newPortfolioMode = mode
        this.newPortfolioId = portfolioId || ''
        this.openModal('new_portfolio')
      })
      this.$nuxt.$on('modals-trade-detail', (tradeInfo) => {
        this.tradeDetail = true
        if (!tradeInfo.tradeId || !tradeInfo.portfolioId) return
        this.tradeInfo = tradeInfo
      })

      this.$nuxt.$on('bv::modal::hidden', (bvModalEvent, modalId) => {
        if (modalId === 'trade_detail') {
          this.tradeDetail = null
          this.tradeInfo = null
        }
      })
      this.$nuxt.$on('modal-view', (modalData) => {
        this.$axios.post('/api/tools/mp/modal-view', modalData)
      })

      this.$nuxt.$on('modal-source', (eventSource) => {
        this.eventSource = eventSource
      })

      this.$nuxt.$on('show-portfolios-modal', () => {
        this.openModal('portfolios_modal')
      })

      this.$nuxt.$on('show-portfolio-modal', (data) => {
        if (!data.portfolioSlug) return
        this.portfolioModalSlug = data.portfolioSlug
        this.openModal('portfolio_page_modal')
      })

      this.$nuxt.$on('modals-allocation-detail', (asset, portfolio) => {
        let portfolioObj = portfolio
        if (typeof portfolio === 'string') portfolioObj = this.portfolioList[portfolio]
        if (!asset || !portfolio) return
        this.trade = asset
        this.portfolio = portfolioObj
        this.openModal('allocation_detail')
      })
      this.$nuxt.$on('modals-history-modal', (data) => {
        if (!data.portfolioId) {
          return
        }
        this.portfolioId = data.portfolioId
        this.portfolioName = data.portfolioName
        this.openModal('history_modal')
      })
      this.$nuxt.$on('modals-video-feed', (video, portfolioId) => {
        this.video = video
        this.portfolioId = portfolioId
        this.videoModal = true
      })
      this.$nuxt.$on('clear-video-modal', () => {
        this.videoModal = null
      })

      this.$nuxt.$on('feed-text-update-modal', (report) => {
        this.report = true
        this.textUpdate = report
      })

      this.$nuxt.$on('clear-text-update', () => {
        this.report = null
        this.$router.replace({ query: null })
      })

      this.$nuxt.$on('modals-new-video', (mode, video, portfolioId) => {
        this.newVideoMode = mode
        this.video = video
        this.portfolioId = portfolioId
        this.openModal('new_video')
      })

      this.$nuxt.$on('show-deprecated-portfolio-modal', (portfolioId) => {
        this.portfolioId = portfolioId
        this.openModal('deprecated_portfolio_modal')
      })

      this.$nuxt.$on('new-trade-idea', (portfolioSlug) => {
        this.portfolioSlug = portfolioSlug || ''
        this.openModal('new_trade_idea')
      })
      this.$nuxt.$on('trade-admin-tool-show-form', (modeObj) => {
        const mode = modeObj.mode
        if (mode !== 'closeForm') return
        this.portfolioSlug = null
        this.$bvModal.hide('new_trade_idea')
      })

      // Ao Vivo
      this.$nuxt.$on('modals-stream-admin-modal', () => {
        this.openModal('stream_admin')
      })
      this.$nuxt.$on('modals-user-moderation-modal', () => this.openModal('user_moderation_modal'))
      this.$nuxt.$on('modals-image-modal', () => this.openModal('image_modal'))
      this.$nuxt.$on('modals-upload-modal', () => this.openModal('upload_modal'))
      this.$nuxt.$on('modals-avatar-modal', () => this.openModal('avatar_modal'))

      // Feed
      this.$nuxt.$on('modals-new-feed', (portfolioId) => {
        this.feedPortfolioId = portfolioId
        this.feedMode = 'new-update'
        this.openModal('update_feed_modal')
      })
      this.$nuxt.$on('close-feed-modal', () => {
        this.resetState()
      })
      this.$nuxt.$on('update-feed', (event) => {
        this.feedEvent = event
        this.feedMode = event.type === 'trade' ? 'thesis-mode' : 'edit-update'
        this.openModal('update_feed_modal')
      })
      this.$nuxt.$on('set-event-to-pdf-modal', (data) => {
        if (data) this.eventData = data
        this.openModal('event_to_pdf_modal')
      })
      this.$nuxt.$on('clear-uploaded-img', () => {
        this.eventData = null
      })

      // this.$nuxt.$on('open-inter-email-modal', () => {
      // this.openModal('inter_email_modal')
      // })

      // First Follow
      // this.$nuxt.$on('first-follow', (portfolio) => {
      //   this.firstFollow = true
      //   this.portfolio = portfolio
      //   // this.openModal('first_follow_modal')
      // })
      this.$nuxt.$on('clear-first-follow', () => {
        this.firstFollow = null
        this.portfolioName = null
      })

      // Onboarding
      this.$nuxt.$on('onboarding-ready', () => {
        const newSubscription = Object.keys(this.$route.query).includes('new-subscription')
        if (newSubscription && this.isSubscriber) this.openModal('onboarding_modal')
      })

      this.$nuxt.$on('voucher-modal-ready', () => {
        const allowedVouchers = ['TNT', 'PORTAL']
        const newSubscription = Object.keys(this.$route.query).includes('new-subscription')
        if (allowedVouchers.includes(this.voucherCode) && !this.expiredVoucher && !this.subscribedAtLeastOnce && !newSubscription)
          this.openModal('voucher_modal')
      })

      // Subscription -> Update CC / Suspend Subscription
      this.$nuxt.$on('update-cc', () => {
        this.updateCC = true
        this.openModal('update_cc')
      })
      this.$nuxt.$on('update-cc-success', () => {
        this.updateCC = null
        this.$bvModal.hide('update_cc')
      })
      this.$nuxt.$on('suspend-subscription', () => {
        this.openModal('suspend_subscription_modal')
      })

      // Subscription -> Update CC / Suspend Subscription
      this.$nuxt.$on('request-guest-user-signup-modal', (context) => {
        this.signupCtaContext = context?.type
        this.signupCtaContextSpecific = context?.specific || null
        this.openModal('signup_modal')
      })
      this.$nuxt.$on('open-mirroring-modal', () => {
        this.openModal('mirroring_modal')
      })
    })
  },

  updated() {
    this.$nextTick(() => {
      if (this.videoModal && this.$refs.video_modal !== 'undefined') this.openModal('video_feed')

      if (this.report && this.$refs.text_update_modal !== 'undefined') this.openModal('feed_text_update')

      // if (this.firstFollow && this.$refs.first_follow_modal !== 'undefined') this.openModal('first_follow_modal')

      if (this.tradeDetail && this.$refs.trade_detail_modal !== 'undefined') this.openModal('trade_detail')

      if ((!this.email || this.pdfOnly) && this.$refs.complete_profile_modal !== 'undefined') this.openModal('complete_profile_modal')

      // if (this.$cookies.get('hasLandedInPdt') === 0) {
      // if (this.isGuestUser) return this.$cookies.remove('hasLandedInPdt')
      // this.openModal('inter_email_modal')
      // }
    })
  },

  methods: {
    ...mapMutations({
      resetState: 'updateFormData/resetState',
    }),
    openModal: _.debounce(function (modalId) {
      setTimeout(() => {
        this.$bvModal.show(modalId)
      }, 250)
    }, 100),
  },
}
</script>
