export default function timeLimitedAck(socket, callback, timeout = 15000) {
  let called = false

  setTimeout(() => {
    if (called) return
    called = true
    if (socket && socket.connected) socket.disconnect()
    callback(new Error('ETIMEDOUT'))
  }, timeout)

  return (...args) => {
    if (called) return
    called = true
    // eslint-disable-next-line node/no-callback-literal
    callback(...args)
  }
}
