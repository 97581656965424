function toastConfig(config) {
  const defaultConfig = {
    duration: 5000,
    keepOnHover: true,
    action: {
      text: 'X',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      },
    },
  }
  if (!config) return defaultConfig
  if (config.action && config.action.constructor !== Array) config.action = [config.action]
  if (config.action) {
    config.action = config.action.map((a) => {
      if (a.onClick === 'goAway') a.onClick = (e, toastObject) => toastObject.goAway(0)
      return a
    })
  }
  return { ...defaultConfig, ...config }
}

export { toastConfig }
