<script>
export default {
  head() {
    return this.customHead
  },
  computed: {
    customHead() {
      const baseHead = {
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
          },
          {
            name: 'msapplication-TileColor',
            content: '#ffffff',
          },
          {
            name: 'msapplication-TileImage',
            content: '/ms-icon-144x144.png',
          },
          {
            hid: 'twitter:card',
            property: 'twitter:card',
            content: 'summary_large_image',
          },
        ],
        link: [
          {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css?family=Maven+Pro:400,500,600,700&display=swap',
            crossorigin: 'anonymous',
          },
        ],
      }
      if (this.$activeRealm === 'cdi') {
        baseHead.title = 'Carteira do Investidor - Sugestões + confiáveis de Ações e Cripto'
        baseHead.meta.push(
          {
            property: 'og:url',
            content: 'https://cdi.com.br/',
          },
          {
            hid: 'description',
            name: 'description',
            content:
              'Siga carteiras de Ações, Futuros, Cripto, BDRs e FIIs com resultados transparentes e sem enrolação. Chegou a Carteira do Investidor, mas pode chamar de CDI.',
          },
          {
            property: 'og:title',
            content: 'Carteira do Investidor - Sugestões + confiáveis de Ações e Cripto',
          },
          {
            property: 'og:image',
            content: 'https://res.cloudinary.com/portal-do-trader/image/upload/q_auto:good/cdicombr/cdi/assets/og-cdi.png',
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: 'https://res.cloudinary.com/portal-do-trader/image/upload/q_auto:good/cdicombr/cdi/assets/og-image-2x1.jpg',
          },
          {
            name: 'theme-color',
            content: '#34355B',
          }
        )
        baseHead.link.push({ rel: 'icon', type: 'image/x-icon', href: '/favicon-cdi.ico' })
      } else if (this.$activeRealm === 'pdt') {
        baseHead.title = 'Chat Ao Vivo com Traders Profissionais, Notícias e Agenda Econômica - Portal do Trader'
        baseHead.meta.push(
          {
            property: 'og:url',
            content: 'https://app.portaldotrader.com.br/',
          },
          {
            property: 'og:title',
            content: 'Chat Ao Vivo com Traders Profissionais, Notícias e Agenda Econômica - Portal do Trader',
          },
          {
            hid: 'description',
            name: 'description',
            content: 'Junte-se a 300 mil traders e aprenda a operar Day Trade e Price Action com Caio Sasaki, Eduardo Becker e grandes nomes.',
          },
          {
            property: 'og:image',
            content: 'https://res.cloudinary.com/portal-do-trader/image/upload/q_auto:good/cdicombr/cdi/assets/og-pdt.png',
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: 'https://res.cloudinary.com/portal-do-trader/image/upload/q_auto:good/cdicombr/cdi/assets/og-pdt-2x1.jpg',
          },
          {
            name: 'theme-color',
            content: '#182944',
          }
        )
        baseHead.link.push({ rel: 'icon', type: 'image/x-icon', href: '/favicon-pdt.ico' })
      } else if (this.$activeRealm === 'inter') {
        baseHead.title = 'Mercado Ao Vivo Inter Invest'
        baseHead.meta.push(
          {
            property: 'og:url',
            content: 'https://mercadoaovivo.bancointer.com.br/',
          },
          {
            property: 'og:title',
            content: 'Inter. Simplifica a vida.',
          },
          {
            hid: 'description',
            name: 'description',
            content: 'Inter Invest: descubra as melhores estratégias de trading e acompanhe o mercado com especialistas.',
          },
          {
            property: 'og:image',
            content: 'https://res.cloudinary.com/portal-do-trader/image/upload/q_auto:good/cdicombr/cdi/assets/og-inter.png',
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: 'https://res.cloudinary.com/portal-do-trader/image/upload/q_auto:good/cdicombr/cdi/assets/og-inter-2x1.jpg',
          },
          {
            name: 'theme-color',
            content: '#ff7b00',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'index, follow',
          }
        )
        baseHead.link.push(
          { rel: 'icon', type: 'image/x-icon', href: '/favicon-inter.ico' },
          {
            rel: 'preconnect',
            href: 'https://fonts.googleapis.com',
          },
          {
            rel: 'preconnect',
            href: 'https://fonts.gstatic.com',
            crossorigin: 'anonymous',
          },
          {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Sora:wght@400;600;800&display=swap',
          },
          {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap',
          }
        )
      }
      return baseHead
    },
  },
}
</script>
