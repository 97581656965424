function setHomeVersionCookie(context) {
  // Cookie - Cdi home version
  // No longer needed - Clean up dependencies to remove it
  const { app, cookies } = context
  if (!app.$cookies.get('homeVersion')) cookies.push(['homeVersion', 'A', { path: '/', maxAge: 60 * 60 * 24 * 30 }])
}
// function setOnboaringCookie(context) {
// // Cookie - Inter Onboarding Experience
// const { app, store, cookies } = context
// const isGuestUser = store.state.auth.loggedIn && store.state.auth.user.hats.guest
// const interRealm = store.state.realms.activeRealm === 'inter'
// const cookieRandomContent = Math.random() <= 0.5 ? 'hidden-onboarding' : 'visible-onboarding'
// if (!app.$cookies.get('interOnboardingExperience') && !isGuestUser && interRealm)
// cookies.push(['interOnboardingExperience', cookieRandomContent, { path: '/', maxAge: 60 * 60 * 24 * 30 }])
// }

function setAbCookies(app, store, cookies) {
  const context = { app, store, cookies }
  setHomeVersionCookie(context)
}
export { setAbCookies }
