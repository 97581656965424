const user = {
  _id: null,
  auth: {
    email: 'convidado@email.com',
    whatsapp: '5500000000000',
    validWhatsapp: true,
    otp: '',
    otpAttempts: 0,
    lastOtp: 0,
    password: null,
    validEmail: true,
    validEmailHash: '',
    name: 'Convidado',
    googleId: null,
    facebookId: null,
    inviteCode: '',
    realm: 'pdt',
    iuguId: '',
  },
  chat: {
    nickname: 'Convidado',
    plainNickname: 'Convidado',
    avatarColor: 1,
    cloudinaryAvatar: null,
    cloudinaryNickname: null,
    badgeText: null,
    badgeColor: null,
  },
  hats: {
    uploader: false,
    moderator: false,
    liveAdmin: false,
    cnpi: false,
    amateurAnalyst: false,
    partner: false,
    su: false,
    guest: true,
  },
  subscription: {
    paymentMethod: null,
    subscriptionId: null,
    plan: null,
    active: null,
    expiry: '20300101',
    trialStart: new Date(),
    usedVoucher: null,
  },
  portfolios: {
    following: [],
    whatsappNotificationOptout: [],
  },
  flags: {
    showOnboardingTooltips: true,
    pdfOnly: false,
    partnerSlug: null,
    chosenPortfolioType: null,
  },
  insights: {
    firstFrom: 'direct',
    lastFrom: 'direct',
  },
  mailing: {
    digest: false,
  },
  invitees: [],
  feed: {
    banned: false,
    cursed: false,
  },
}

function setGuestUser(app, realm) {
  const following = user.portfolios.following
  const storedPortfolio = app.$cookies.get('local-following')
  const followingStoredPortfolio = following?.includes(storedPortfolio)
  user.insights.lastFrom = app.$cookies.get('lastFrom') || `direct`
  user.auth.realm = realm
  if (storedPortfolio && !followingStoredPortfolio) following.push(storedPortfolio)
  app.$auth.setUser(user)
}

export default setGuestUser
